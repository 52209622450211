import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import JSONCategories from '../content/pages/products.json'
import { Border } from '../components/IndexPage'
import { HeroContainer, Title } from '../components/SubIndex'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import StockList from '../assets/pdf/Stock_List.pdf'
import '../css/documents.css';
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Footer } from '../components/Footer'
import { Card } from '../components/Card'
import { ProductTable } from '../components/Table'
import qs from 'query-string'
import { CardCollapsible } from '../components/CardCollapsible'
import '../css/products.css'

const ProductItems = styled.div`
  min-height: 315px;
  ${tw`mb-6`}
`
const CardWrapper = styled.div`
  ${tw``}
  flex-wrap: wrap;
`

const Products = ({ location, }) => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "20200203-05_US_Stewart_Sutherland-190b.jpg" }) {
        childImageSharp {
          fluid (quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "20200203-05_US_Stewart_Sutherland-190b.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid (quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      category_1: file(relativePath: { eq: "240-Baker-Deli-Grocery-Image.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      category_2: file(relativePath: { eq: "Fast-Food-Concessions.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      category_3: file(relativePath: { eq: "Pizza-Subs-Takeout.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      category_4: file(relativePath: { eq: "Silverware.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const onSetCategoryId = (_categoryId) => {
    navigate(`/products?category=${_categoryId}`)
  }
  const queries = qs.parse(location.search)
  let categoryId = 0
  try {
    categoryId = parseInt(queries.category, 0)
  } catch (error) {
    console.log('error: ',error)
  }
  const productItemsData = JSONCategories.product_items.find(itm => itm.pid === categoryId)

  return (
   <Layout>
   <div className='relative z-0'>
     <div className='md:hidden bg-black'>
       <Img
         fluid={data.mobileImage.childImageSharp.fluid}
         className='-mt-12 md:-mt-6 md:mb-0 w-full'
         alt='hero-image'
         style={{ height: 300, opacity: 0.7, }}
       />
     </div>
     <div className='hidden md:block md:w-full bg-black'>
       <Img
         fluid={data.desktopImage.childImageSharp.fluid}
         className='-mt-12 md:-mt-6 md:mb-0 w-full'
         alt='hero-image'
         style={{ height: 420, opacity: 0.7, }}
       />
     </div>
     <HeroContainer>
       <Title className='inline-block'>
         Products
         <Border thick className='mt-2 bg-primary' />
       </Title>
       <div className='max-w-md xl:max-w-2xl'>
         <p className='xl:block mt-4 open-sans text-gray-100 tracking-wide text-base md:text-base'>
           We offer a wide range of stock and custom designed products. Our
           graphics department and production team can help design products
           specifically suited to your needs
         </p>
       </div>
     </HeroContainer>
     </div>
     <div className='container p-0 xl:p-6 lg:p-6 md:py-24  '>
         <div className='itembox'>
            <a
              href={StockList}
              download
              className='text-right my-8 bg-primary text-white hover:text-black hover:bg-gray-200 p-2 rounded'
            >
              Download Stock List
            </a>
            <iframe className="mt-8" src="https://drive.google.com/file/d/1df1e2pFUOrW-2kMzY23qWWtIwbNMvgNH/preview" width="100%" height="600" allow="autoplay"></iframe>

         </div>
     </div>
      <Footer/>
     </Layout>

  )
}

export default Products